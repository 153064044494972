@import '~scss/variables.scss';

.errorView {
	@include padding-large;
	height: 100vh;
	background: $color-gray-6;
	&.isInAppFrame {
		background: $color-white;
		height: 100%;
	}
}
