@import "~scss/variables.scss";
.links {
  display: flex;
  justify-content: center;
  @include tablet-down {
    flex-wrap: wrap;
    a {
      width: calc(33.33% - 2rem);
    }
  }
  a {
    margin: 1rem;
    color: $color-gray-4;
    text-transform: uppercase;
  }
}
.bandPhoto {
  max-width: 100%;
  margin: 4rem auto;
  display: block;
}
.albumCover {
  max-width: 80rem;
  margin: 4rem auto;
  display: block;
}
body {
  text-align: center;
}
.subscribeButton {
  margin: 0 auto;
}
