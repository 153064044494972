@import '~scss/variables.scss';

.messageContainer {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column-reverse;
	z-index: $z-index-message;
	@include margin-small;
	@include tablet-up {
		left: auto;
		width: $size-column * 4 + $size-gutter * 3;
		top: 0;
		bottom: auto;
		flex-direction: column;
	}
}

.message.message {
	box-shadow: $box-shadow-large;
	margin-top: 0;
}
