@import '~scss/variables.scss';

.container {
	width: calc(100% - #{$size-container-margin-mobile * 2});
	@include tablet-up {
		width: calc(100% - #{$size-container-margin-desktop * 2});
	}
	margin: 0 auto;
	position: relative;
	max-width: #{$container-width}rem;
}
