@import '~scss/variables.scss';

.breadcrumbs {
	line-height: 0;
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: auto;
	padding-right: 1rem;
}

.breadcrumbsWrapper {
	position: relative;

	&:after {
		content: '';
		display: block;
		height: 100%;
		width: 1rem;
		position: absolute;
		right: -1rem;
		top: 0;
		transform: translateX(-100%);
		background: rgb(255, 255, 255);
		background: linear-gradient(90deg, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);
	}
}

.breadcrumbLink {
	text-decoration: none;
	&[aria-current] {
		font-weight: $font-weight-bold;
	}
	&:last-child {
		padding-right: 1rem;
	}
}

.separator {
	margin: 0 0.1rem 0 0.2rem;
}

.breadcrumbLink {
	@include type-body;
}
