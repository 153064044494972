body,
input,
textarea,
select,
button {
	font-family: $font-family-body;
	@include type-body;
}

// This keeps mobile Safari from zooming in on the input fields
input,
textarea,
select {
	@include mobile-down {
		@include type-200;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $font-family-body;
	margin-bottom: 0.6em;
	font-weight: $font-weight-regular;
}
h1,
.h1 {
	@include type-h1;
}
h2,
.h2 {
	@include type-h2;
}
h3,
.h3 {
	@include type-h3;
}
h4,
.h4 {
	@include type-h4;
}
h5,
.h5 {
	@include type-h5;
}
h6,
.h6 {
	@include type-h6;
}

a {
	@include link;
}

ol,
ul {
	padding-inline-start: unset;
}

ul {
	list-style: none;
}

ol {
	padding-left: 1.4rem;
	margin: 2rem 0;
}

p,
li {
	font-family: $font-family-body;
	font-weight: $font-weight-regular;
	margin: 2rem 0;
	@include type-body;
}

li {
	margin: 0 0;
}

p li {
	transform: translateY(0);
}

.heading {
	margin-top: 0 !important;
}
