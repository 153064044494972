@import '~scss/variables.scss';

@mixin icons-to-fill {
	&.CaretDownFilled,
	&.CaretUpFilled {
		@content;
	}
}

@mixin on-button-control-hover {
	:global {
		.button:hover {
			:local {
				.control {
					@content;
				}
			}
		}
	}
}

.icon {
	@include all-svg-elements {
		stroke-width: 0.15rem;
	}
}

.gray {
	@include all-svg-elements {
		stroke: $color-gray-4 !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-gray-4 !important;
		}
	}
}

@include on-button-control-hover {
	&.gray {
		@include all-svg-elements {
			stroke: $color-gray-3 !important;
		}
	}
}

.grayLight {
	@include all-svg-elements {
		stroke: $color-gray-5 !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-gray-5 !important;
		}
	}
}

@include on-button-control-hover {
	&.gray {
		@include all-svg-elements {
			stroke: $color-gray-3 !important;
		}
	}
}

.white {
	@include all-svg-elements {
		stroke: $color-white !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-white !important;
		}
	}
}

@include on-button-control-hover {
	&.white {
		@include all-svg-elements {
			stroke: $color-gray-4 !important;
		}
	}
}

.blue {
	@include all-svg-elements {
		stroke: $color-blue-base !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-blue-base !important;
		}
	}
}

@include on-button-control-hover {
	&.blue {
		@include all-svg-elements {
			stroke: $color-blue-light !important;
		}
	}
}

.red {
	@include all-svg-elements {
		stroke: $color-red-base !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-red-base !important;
		}
	}
}

@include on-button-control-hover {
	&.red {
		@include all-svg-elements {
			stroke: $color-red-light !important;
		}
	}
}

.green {
	@include all-svg-elements {
		stroke: $color-blue-base !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-blue-base !important;
		}
	}
}

@include on-button-control-hover {
	&.green {
		@include all-svg-elements {
			stroke: $color-green-light !important;
		}
	}
}

.black {
	@include all-svg-elements {
		stroke: $color-gray-1 !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-gray-1 !important;
		}
	}
}

@include on-button-control-hover {
	&.black {
		@include all-svg-elements {
			stroke: $color-gray-3 !important;
		}
	}
}

.orange {
	@include all-svg-elements {
		stroke: $color-orange-base !important;
	}
	@include icons-to-fill {
		@include all-svg-elements {
			fill: $color-orange-base !important;
		}
	}
}

@include on-button-control-hover {
	&.red {
		@include all-svg-elements {
			stroke: $color-red-light !important;
		}
	}
}

.smallest {
	@include icon-size(1.8);
}

.small {
	@include icon-size(2);
}

.medium {
	@include icon-size(2.4);
}

.large {
	@include icon-size(4);
}

.largest {
	@include icon-size(6);
}

.giant {
	@include icon-size(10);
}

.none {
	@include all-svg-elements {
		stroke-width: 0;
	}
}

.thick {
	@include all-svg-elements {
		stroke-width: 2px !important;
	}
}

.skeleton {
	border-radius: 50%;
	display: inline-block;
}

.waitingComponent {
	display: inline-block;
}

.tooltip {
	background-color: $color-blue-base;
	color: $color-white;
	padding: $size-16 2rem;
	border-radius: $border-radius;
	z-index: $z-index-tooltip;
	max-width: 18rem;
	@include type-080;
}

.margin-small {
	margin-left: 0.5rem;
}

.margin-medium {
	margin-left: 0.8rem;
}

.margin-large {
	margin-left: 1rem;
}
