@import '~scss/variables.scss';

@include button;

//Adding local removes namespace conflict with type
.linkLocal {
	display: table;
	* {
		pointer-events: auto;
	}
}
