@import '~scss/variables';

.spinner {
	&.lighter {
		path {
			stroke: $color-white !important;
		}
	}
	&.light {
		path {
			stroke: $color-gray-4 !important;
		}
	}
	&.dark {
		path {
			stroke: $color-gray-3 !important;
		}
	}
	&.darker {
		path {
			stroke: $color-gray-1 !important;
		}
	}
	path {
		stroke: $color-gray-1;
		@for $i from 1 through 12 {
			&:nth-child(#{$i}) {
				transition: all 0.2s #{$i * 0.1}s ease-in;
				opacity: 0.1;
				animation-name: spin#{$i};
				animation-duration: 1.8s;
				animation-iteration-count: infinite;
				animation-delay: #{$i * 0.15}s;
			}
		}
	}
}

@for $i from 1 through 12 {
	@keyframes spin#{$i} {
		from {
			opacity: 1;
		}

		to {
			opacity: 0.1;
		}
	}
}

.isCenteredVertically {
	margin-top: 40vh;
}

.isCenteredHorizontally {
	margin-right: auto;
	margin-left: auto;
	display: block;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
