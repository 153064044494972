form {
	width: 100%;
}
@mixin input {
	position: relative;
	width: 100%;
	border: 0.1rem solid $color-gray-4;
	border-radius: $border-radius;
	margin: 0;
	padding: 1rem 2rem $size-12 2rem;
	@include margin-small-bottom;
	&::placeholder {
		color: $color-gray-3;
	}
}

@mixin label {
	display: block;
	transform: translateY(-1px);
	padding-left: 0;
	@include type-080;
	margin-bottom: $size-12;
	text-transform: uppercase;
	color: $color-gray-2;
	font-weight: $font-weight-bold;
	&.labelError {
		color: $color-red-base;
	}
}

input {
	@include input;
}

label {
	@include label;
}
