@import '~scss/variables.scss';

.pageFrame {
	margin-top: $size-40;
}

.pageFrameTop {
	@include tablet-up {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.pageFrameTopInner {
	min-width: 0;
}

.pageFrameTitle {
	margin: 0 auto 0 auto !important;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.backLocation {
	display: inline-block;
	@include margin-small-top;
}

.backLocationIcon {
	margin-right: 0.5rem;
}

.contentTopRight {
	@include margin-medium-top;
	@include tablet-up {
		margin-top: -0.8rem;
		margin-bottom: -0.8rem;
	}
}

.content {
	@include padding-small-vertical;
}

.breadcrumbs {
	margin-bottom: 1.2rem;
}
